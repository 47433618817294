var render = function render(){var _vm=this,_c=_vm._self._c;return _c('x-modal-center',{attrs:{"bilingual":true,"apply-button-text":"save","cancel-button-text":"cancel","footer-class":"d-flex justify-content-between x-gap-1","apply-button-class":"w-100","cancel-button-class":"w-100","apply-button-loading":_vm.applyButtonLoading,"modal-container-style":{
    'max-width': '960px',
    'max-height': '720px',
    // width: '75%',
    // height: '100%',
    overflowY: 'scroll',
  }},on:{"close":_vm.closeModal,"cancel":_vm.closeModal,"apply":_vm.saveItem},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',{staticClass:"x-font-size-36px font-craftworksans color-gray-600"},[_vm._v(" "+_vm._s(_vm.$t("settings_translations_edit_tags"))+" ")])]},proxy:true},{key:"body",fn:function(){return [_c('validation-observer',{ref:"creating-observer",staticClass:"client-type-creating-body"},[_c('validation-provider',{ref:"clientTypeNameVProvider",staticClass:"title-uz-provider",attrs:{"name":"last-name-uz-provider","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('x-form-input',{staticClass:"w-100",attrs:{"disable":"","type":"text","placeholder":_vm.$t('key'),"value":_vm.editItem.key}}),(errors[0])?_c('span',{staticClass:"error__provider"},[_vm._v(" "+_vm._s(errors[0].replace("last-name-uz-provider", _vm.$t("title")))+" ")]):_vm._e()]}}])}),_c('validation-provider',{staticClass:"filter__inputs-input",attrs:{"name":"tag-input","rules":"requred"}},[_c('base-form-tag-input',{ref:"base-form-tag-input",attrs:{"default-tags":_vm.item.tags,"placeholder":`${_vm.$t('tags')}`},on:{"set-tags":_vm.setTags},scopedSlots:_vm._u([{key:"delete-content",fn:function(){return [_c('svg',{attrs:{"width":"20","height":"20","viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"10","cy":"10","r":"10","fill":"#9CA3AF"}}),_c('path',{attrs:{"d":"M13.125 6.875L6.875 13.125","stroke":"white","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M6.875 6.875L13.125 13.125","stroke":"white","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]},proxy:true}])})],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }