import { render, staticRenderFns } from "./SettingsCreateTranslation.vue?vue&type=template&id=786d6eee&scoped=true"
import script from "./SettingsCreateTranslation.vue?vue&type=script&lang=js"
export * from "./SettingsCreateTranslation.vue?vue&type=script&lang=js"
import style0 from "./SettingsCreateTranslation.vue?vue&type=style&index=0&id=786d6eee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "786d6eee",
  null
  
)

export default component.exports